export default {
	messages: {
		ru: {
			'Журнал моих звонков': 'Журнал моих звонков',
			'Все': 'Все',
			'Целевой': 'Целевой',
			'Нецелевой': 'Нецелевой',
			'Номер телефона менеджера': 'Номер телефона менеджера',
			'Уникальные звонки': 'Уникальные звонки',
			'Статус': 'Статус',
			'Время': 'Время',
			'Номер телефона': 'Номер телефона',
			'Длительность': 'Длительность',
			'Стоимость': 'Стоимость',
			'Не состоялся': 'Не состоялся',
			'У вас нет звонков за указанный период': 'У вас нет звонков за указанный период',
			'Принятый': 'Принятый',
			'Без ответа': 'Без ответа',
			'Линия занята': 'Линия занята',
			'На модерации': 'На модерации',
			'Отвеченный': 'Отвеченный',
			'Возврат подтвержден': 'Возврат подтвержден',
			'Возврат отклонен': 'Возврат отклонен',
			'Просмотреть': 'Просмотреть'
		},
		uk: {
			'Журнал моих звонков': 'Журнал моїх дзвінків',
			'Все': 'Усі',
			'Целевой': 'Цільовий',
			'Нецелевой': 'Нецільовий',
			'Номер телефона менеджера': 'Номер телефону менеджера',
			'Уникальные звонки': 'Унікальні дзвінки',
			'Статус': 'Статус',
			'Время': 'Час',
			'Номер телефона': 'Номер телефону',
			'Длительность': 'Тривалість',
			'Стоимость': 'Вартість',
			'Не состоялся': 'Не відбувся',
			'У вас нет звонков за указанный период': 'У вас немає дзвінків за вказаний період',
			'Принятый': 'Прийнятий',
			'Без ответа': 'Без відповіді',
			'Линия занята': 'Лінія зайнята',
			'На модерации': 'На модерації',
			'Отвеченный': 'Прийнятий',
			'Возврат подтвержден': 'Повернення підтверджене',
			'Возврат отклонен': 'Повернення відхилене',
			'Просмотреть': 'Переглянути'
		}
	}
};
