export default {
	messages: {
		uk: {
			'Дата, время': 'Дата, час',
			'Номер телефона': 'Номер телефону',
			'Целевой': 'Цільовий',
			'Нецелевой': 'Нецільовий',
			'Прослушать запись звонка': 'Прослухати запис дзвінка',
			'Не состоялся': 'Не відбувся',
			'Стоимость': 'Вартість',
			'Ваш браузер не поддерживает аудио элементы.': 'Ваш браузер не підтримує аудіо елементи.',
			'Оспорить звонок': 'Оскаржити дзвінок',
			'Закрыть': 'Закрити',
			'Без ответа': 'Без відповіді',
			'Принятый': 'Прийнятий',
			'Линия занята': 'Лінія зайнята',
			'На модерации': 'На модерації',
			'Отвеченный': 'Прийнятий',
			'Возврат подтвержден': 'Повернення підтверджене',
			'Возврат отклонен': 'Повернення відхилене',
			'Отправить на повторную модерацию': 'Відправити на повторну модерацію',
			'Укажите причину отправки на модерацию': 'Вкажіть причину відправки на модерацію',
			'Комментарий к звонку': 'Коментар до дзвінка',
			'Введите текст комментария': 'Введіть текст коментаря',
			'Добавить комментарий': 'Додати коментар',
			'Сохранить': 'Зберегти',
			'Хотите удалить комментарий': 'Чи бажаєте видалити коментар',
		},
		ru: {
			'Дата, время': 'Дата, время',
			'Номер телефона': 'Номер телефона',
			'Целевой': 'Целевой',
			'Нецелевой': 'Нецелевой',
			'Прослушать запись звонка': 'Прослушать запись звонка',
			'Не состоялся': 'Не состоялся',
			'Стоимость': 'Стоимость',
			'Ваш браузер не поддерживает аудио элементы.': 'Ваш браузер не поддерживает аудио элементы.',
			'Оспорить звонок': 'Оспорить звонок',
			'Закрыть': 'Закрыть',
			'Без ответа': 'Без ответа',
			'Принятый': 'Принятый',
			'Линия занята': 'Линия занята',
			'На модерации': 'На модерации',
			'Отвеченный': 'Отвеченный',
			'Возврат подтвержден': 'Возврат подтвержден',
			'Возврат отклонен': 'Возврат отклонен',
			'Отправить на повторную модерацию': 'Отправить на повторную модерацию',
			'Укажите причину отправки на модерацию': 'Укажите причину отправки на модерацию',
			'Комментарий к звонку': 'Комментарий к звонку',
			'Введите текст комментария': 'Введите текст комментария',
			'Добавить комментарий': 'Добавить комментарий',
			'Сохранить': 'Сохранить',
			'Хотите удалить комментарий': 'Хотите удалить комментарий',
		}
	}
};
