import {mapGetters, mapActions} from 'vuex';

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {
			moderationMessage: '',
			moderationSended: false,
			commentText: null,
			showSaveComment: false,
			showDelComment: false,
		};
	},
	name: 'CallsModal',
	created() {
	},
	props: ['open', 'wsDataCallLink', 'item', 'callPrice', 'showPrice'],
	methods: {
		...mapActions({
			setСommentToСall: 'others/setСommentToСall',
		}),
		closeModal() {
			this.$refs.audioElm.pause();
			this.$emit('onCloseModal');
			this.localComment = null;
		},
		sendToModeration() {
			if (this.moderationMessage.length >= 3) {
				this.$emit('onCloseModal', {
					'dispute_call_id': this.item.callId,
					'dispute_comment': this.moderationMessage,
				});
				this.moderationMessage = '';
			}
		},
		async sendCommentToCall() {
			const newComment = await this.setСommentToСall({
				callId: this.item.id,
				comment: this.showDelComment ? null: this.localComment,
			});

			if (newComment) {
				if (this.showDelComment) {
					this.localComment = null;
					this.showSaveComment = false;
					this.showDelComment = false;
					this.$emit('onChangeCommentCall', null);
				} else {
					this.showSaveComment = false;
					this.$emit('onChangeCommentCall', this.localComment);
				}
			}
		},
	},
	computed: {
		...mapGetters({
			segment: 'cabinet/segment',
			userData: 'cabinet/userInfo/userData',
		}),
		localComment: {
			get() {
				return this.commentText || this.item.comment;
			},
			set(value) {
				this.commentText = value;
			},
		},
		comment() {
			return this.item.comment;
		},
	}

};
