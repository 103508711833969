import { mapGetters } from 'vuex';
import moment from 'moment';
import Pagination from '../../Common/Pagination/index.vue';
import CallsModal from './CallsModal/index.vue';
import CallBudgetBlock from './CallBudgetBlock/index.vue';

// тут такі налаштування webPack, що я не зміг підключити config
// якщо ти зміг, то в тебе просто було більше часу чим у мене
let config;
switch (process.env.NODE_ENV) {
  case 'development':
    config = {
      ws: {
        host: 'ws://autolocal.ria.com',
        calls: {
          url: '/ws/calls',
        },
      },
    };
    break;
  case 'dev-cluster':
    config = {
      ws: {
        host: 'ws://dev-auto.ria.com',
        calls: {
          url: '/cabinet/ws/calls',
        },
      },
    };
    break;
  case 'production':
  default:
    config = {
      ws: {
        host: 'wss://auto.ria.com',
        calls: {
          url: '/cabinet/ws/calls',
        },
      },
    };
    break;
}

export default {
  i18n: require('./i18n').default,
  data: function () {
    return {
      wsDataFilter: {},
      wsDataCalls: [],
      wsDataCount: undefined,
      page: 1,
      dateFrom: moment()
        .subtract(30, 'day')
        .format('YYYY-MM-DD'),
      dateTo: moment()
        .format('YYYY-MM-DD'),
      webSocket: undefined,
      status: 0,
      paidStatus: 0,
      wsOpen: false,
      sendMsg: false,
      offset: 0,
      openModal: false,
      wsDataCallLink: undefined,
      currentItem: {},
      phone: 0,
      callPrice: 150,
      showPrice: false,
      isUnique: 1
    };
  },
  components: {
    'pagination': Pagination,
    'calls-modal': CallsModal,
    'calls-budget-block': CallBudgetBlock,
  },
  name: 'CallsJournal',
  created() {
    if (_TARGET_ === 'client') {
      this.createConnection()
        .then(function () {
          this.getData();
        }.bind(this));
    }

    // масив Ткачука
    let payForCallArr = [8396905, 3084767, 4961996, 8465782, 9178492, 8659136, 7935212];
    const uniquePrice = [9178492, 8659136];
    if (payForCallArr.indexOf(this.userData.id) !== -1) {
      this.showPrice = true;
      if (uniquePrice.indexOf(this.userData.id) !== -1) {
        this.callPrice = 300;
      }
    }
  },
  methods: {
    createConnection() {
      return new Promise(function (resolve, reject) {
        this.webSocket = new WebSocket(config.ws.host + config.ws.calls.url);
        this.webSocket.onmessage = function (response) {
          let tmpData = JSON.parse(response.data);
          if (tmpData.call_link) {
            this.wsDataCallLink = tmpData.call_link;
          } else if (tmpData.calls) {
            this.wsDataCalls = tmpData.calls;
            this.wsDataCount = tmpData.count;
            this.wsDataFilter = tmpData.filterParams;
          }
        }.bind(this);

        this.sendMsg = false;
        this.webSocket.onopen = () => {
          setTimeout(function () {
            resolve(this.webSocket);
          }.bind(this), 200);
        };

        this.webSocket.onerror = (e) => {
          reject(e);
        };

      }.bind(this));
    },
    async getData({
      dispute_call_id,
      dispute_comment
    } = {}) {
      // коли конекшн закритий, на нову дію створюємо ще один
      if (this.webSocket.readyState === 3) {
        await this.createConnection();
      }
      this.sendMsg = true;
      this.webSocket.send(JSON.stringify({
        date_to: moment(this.dateTo)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        date_from: this.dateFrom,
        status: Number(this.status) ? this.status : undefined,
        paid_status: Number(this.paidStatus) ? this.paidStatus : undefined,
        offset: this.offset,
        destination_number: Number(this.phone) ? this.phone : undefined,
        is_unique: Number(this.isUnique) ? true : undefined,
        langId: this.langId,
        dispute_call_id,
        dispute_comment
      }));
    },
    onChangePage(page) {
      this.offset = (page - 1) * 10;
      this.page = page;
      this.getData();
    },
    async showModal(item) {
      this.wsDataCallLink = item.call_link || item.callLink || '';
      this.currentItem = item;
      this.openModal = true;
    },
    onCloseModal({
      dispute_call_id,
      dispute_comment
    } = {}) {
      if (dispute_comment) {
        this.getData({
          dispute_call_id,
          dispute_comment
        });
      }
      this.currentItem = {};
      this.openModal = false;
    },
    onChangeCommentCall(comment) {
      this.currentItem.comment = comment;
    }
  },
  watch: {
    dateFrom() {
      this.onChangePage(1);
      !this.sendMsg && this.getData();
    },
    dateTo() {
      this.onChangePage(1);
      !this.sendMsg && this.getData();
    },
    status() {
      this.onChangePage(1);
      !this.sendMsg && this.getData();
    },
    paidStatus() {
      this.onChangePage(1);
      !this.sendMsg && this.getData();
    },
    phone() {
      this.onChangePage(1);
      !this.sendMsg && this.getData();
    },
    isUnique() {
      this.onChangePage(1);
      !this.sendMsg && this.getData();
    },
    expiredAutoDealer: {
      handler({ status }) {
        if (status) {
          this.$router.push({ name: 'CabinetMain' });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      userData: 'cabinet/userInfo/userData',
      segment: 'cabinet/segment',
      getCallTrackingAccountInfo: 'others/callTrackingAccountInfo',
      expiredAutoDealer: 'cabinet/getExpiredAutoDealer',
    }),
  },

};
